import { DeviceResolver } from '../types';
import AbstractLocalDevicesResolver from '../shared/AbstractLocalDevicesResolver';
import handleAndResolveAdditionalLocalDevicesResolvers from '../shared/utils/handleAndResolveAdditionalLocalDevicesResolvers';

export default async function WebChainnedLocalResolversHandler(
  rootValue,
  args,
  context,
  info,
  _mock,
  additionalLocalDevicesResolvers: AbstractLocalDevicesResolver[]
): Promise<DeviceResolver[]> {
  const resolveParams = { rootValue, args, context, info };

  return handleAndResolveAdditionalLocalDevicesResolvers({
    firstResolver: undefined,
    resolverChain: undefined,
    additionalLocalDevicesResolvers,
    resolveParams
  });
}
