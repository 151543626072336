import { fetch } from 'cross-fetch';
import { HttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { createApolloDocumentTransform } from './apolloDocumentTransform';
import { getGraphQLServerUrl } from '../graphqlServerUrl';
import { Stack } from '../../enums';
import getLocalResolversKeysFromCustomResolvers from './utils/getLocalResolversKeysFromCustomResolvers';
import { CustomResolvers } from '../../types';

type CreateApolloClientBaseConfigParams = {
  stack: Stack;
  authTokenCallback: () => Promise<string>;
  isShellPortal: boolean;
  serverUrl?: string;
  customResolvers?: CustomResolvers;
};

const createApolloClientBaseConfig = ({
  stack,
  authTokenCallback,
  isShellPortal,
  serverUrl,
  customResolvers
}: CreateApolloClientBaseConfigParams) => {
  const cache = new InMemoryCache();

  const httpLink = new HttpLink({
    uri: serverUrl || getGraphQLServerUrl(stack, isShellPortal),
    fetch
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await authTokenCallback();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const customResolversKeys =
    getLocalResolversKeysFromCustomResolvers(customResolvers);

  const documentTransform = createApolloDocumentTransform(customResolversKeys);

  const apolloClientConfig = {
    cache,
    link: authLink.concat(httpLink),
    documentTransform
  };

  return apolloClientConfig;
};

export default createApolloClientBaseConfig;
